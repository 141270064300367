import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';

import qrCodeImage from '../../assets/QrCode.png';

import {
  InputGroup,
  Button,
  Select,
  DatePickerInput,
  ImageUpload,
} from '../elements';

const Container = styled.form``;

const membershipTypes = [
  {
    name: 'Annual',
  },
  {
    name: 'Lifetime',
  },
];

const paymentTypes = [
  {
    name: 'Cash',
  },
  {
    name: 'Online',
  },
];

const MemberShipForm = ({
  values,
  touched,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  loading,
}) => {
  return (
    <Container onSubmit={handleSubmit}>
      <div className="mb-4">
        <InputGroup
          label="Full Name"
          name="name"
          type="text"
          placeholder="Enter your full name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.name && touched.name ? errors.name : undefined}
        />
      </div>
      <div className="mb-4">
        <InputGroup
          label="Postal Address"
          name="address"
          type="text"
          placeholder="Enter your postal address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.address && touched.address ? errors.address : undefined
          }
        />
      </div>
      <div className="mb-4">
        <InputGroup
          label="Pin Code"
          name="pinCode"
          type="text"
          placeholder="Enter your pin code"
          value={values.pinCode}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.pinCode && touched.pinCode ? errors.pinCode : undefined
          }
        />
      </div>
      <div className="mb-4">
        <InputGroup
          label="District"
          name="district"
          type="text"
          placeholder="Enter your district"
          value={values.district}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.district && touched.district ? errors.district : undefined
          }
        />
      </div>
      <div className="mb-4">
        <InputGroup
          label="State"
          name="state"
          type="text"
          placeholder="Enter your state"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.state && touched.state ? errors.state : undefined}
        />
      </div>
      <div className="mb-4">
        <InputGroup
          label="Mobile Number"
          name="telephone"
          type="text"
          placeholder="Enter your mobile number"
          value={values.telephone}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.telephone && touched.telephone ? errors.telephone : undefined
          }
        />
      </div>

      <DatePickerInput
        label="Date of Birth"
        name="dob"
        dateFormat
        timeFormat
        value={values.dob}
        onChange={(date) => setFieldValue('dob', date)}
        onBlur={handleBlur}
        error={errors.dob && touched.dob ? errors.dob : undefined}
      />
      <div className="mb-4">
        <InputGroup
          label="Electrohomoeopathy Qualification"
          name="qualification"
          type="text"
          placeholder="Enter your electrohomoeopathy qualification"
          value={values.qualification}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.qualification && touched.qualification
              ? errors.qualification
              : undefined
          }
        />
      </div>

      <div className="mb-4">
        <InputGroup
          label="EH Registration No"
          name="ehRegistrationNo"
          type="text"
          placeholder="Enter your EH registration no"
          value={values.ehRegistrationNo}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.ehRegistrationNo && touched.ehRegistrationNo
              ? errors.ehRegistrationNo
              : undefined
          }
        />
      </div>

      <div className="mb-6">
        <Select
          label="Membership Type"
          data={membershipTypes}
          onChange={(e) => {
            if (e && e.name) {
              setFieldValue('type', e.name);
            }
          }}
        />
        <p className="text-red-500 text-xs italic">{errors.type}</p>
      </div>

      <div className="mb-6">
        <Select
          label="Payment Type"
          data={paymentTypes}
          onChange={(e) => {
            if (e && e.name) {
              setFieldValue('paymentMethod', e.name);
            }
          }}
        />
        <p className="text-red-500 text-xs italic">{errors.type}</p>
      </div>

      {values.paymentMethod === 'Online' && (
        <div>
          <div className="flex flex-col justify-center items-center">
            <img
              alt="QRCODE"
              className="object-cover h-96 w-96"
              src={qrCodeImage}
            />
            <p className="mt-4">Please Scan the QRCode to make payment</p>
          </div>
        </div>
      )}

      {values.paymentMethod === 'Online' && (
        <div className="mt-6">
          <div className="mt-6">
            <ImageUpload
              title="Upload your Payment slip here"
              value={values.paymentSlipImage}
              onSuccess={(val) => setFieldValue('paymentSlipImage', val)}
            />
          </div>
          {errors.paymentSlipImage && touched.paymentSlipImage && (
            <p className="text-red-500 text-xs italic mt-4">
              {errors.paymentSlipImage}
            </p>
          )}
        </div>
      )}

      <div className="mt-6">
        <ImageUpload
          title="Upload your Profile Image"
          value={values.image}
          onSuccess={(val) => setFieldValue('image', val)}
        />
      </div>
      {errors.image && touched.image && (
        <p className="text-red-500 text-xs italic mt-4">{errors.image}</p>
      )}

      <Button
        type="submit"
        className="mt-4 w-full uppercase justify-center"
        onClick={handleSubmit}
        loading={loading}
      >
        Submit
      </Button>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    address: '',
    pinCode: '',
    district: '',
    state: '',
    telephone: '',
    qualification: '',
    ehRegistrationNo: '',
    dob: moment(new Date()).format('YYYY-MM-DD'),
    type: 'Annual',
    image: '',
    paymentSlipImage: '',
  }),
  validationSchema: yup.object().shape({
    name: yup.string().required('Full Name is required!'),
    address: yup.string().required('Address is required!'),
    pinCode: yup.string().required('Pin Code is required!'),
    district: yup.string().required('district is required!'),
    state: yup.string().required('state is required!'),
    telephone: yup.string().required('Mobile Number is required!'),
    qualification: yup
      .string()
      .required('Electrohomoeopathy qualification qualification is required!'),
    ehRegistrationNo: yup.string().required('EH registration no  is required!'),
    dob: yup.string().required('Date of birth is required!'),
    type: yup.string().required('Membership type is required!'),
    image: yup.string().required('Profile Image is required!'),
    paymentMethod: yup.string().required('Payment type is required!'),
    paymentSlipImage: yup.string(),
  }),

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    setSubmitting(false);
    props.handleSubmit(values);
    resetForm();
  },
  displayName: 'MemberShipForm', // helps with React DevTools
})(MemberShipForm);
