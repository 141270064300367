import { useEffect } from 'react';
import swal from 'sweetalert';
import { gql, useMutation } from '@apollo/client';

import Layout from '../components/Layout';
import { Loading } from '../components/elements';

import MemberShipForm from '../components/forms/MemberShipForm';

const registerMemberShiptMutation = gql`
  mutation registerMemberShip($input: RegisterMemberShipInput) {
    registerMember(input: $input) {
      id
    }
  }
`;

const RegisterMember = () => {
  const [executeMutation, { data, loading, error }] = useMutation(
    registerMemberShiptMutation,
  );

  useEffect(() => {
    if (error) {
      swal('Oops', 'Something went wrong please try again later', 'error');
    }
    if (data) {
      swal(
        'Congrats successfully registered',
        'Our team shortly contact you',
        'success',
      );
    }
  }, [data, error]);

  const handleSubmit = async (values) => {
    await executeMutation({
      variables: {
        input: {
          ...values,
        },
      },
    });
  };

  return (
    <Layout>
      <div className="flex justify-center py-8">
        <div className="md:w-6/12 w-11/12">
          <h1 className="uppercase font-bold text-4xl text-center mb-10 text-purple-500 underline pb-2">
            ERDO membership form
          </h1>
          {loading && <Loading />}

          <div className="border-2 px-8 py-6 rounded-2xl border-purple-200 shadow-lg">
            <MemberShipForm handleSubmit={handleSubmit} loading={loading} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterMember;
