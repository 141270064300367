import { useState } from 'react';
import imageCompression from 'browser-image-compression';
import swal from 'sweetalert';
import sanityClient from '../../utils/sanityClient';

const ImageUpload = ({
  title,
  hasButton,
  buttonTitle,
  value = false,
  onSuccess,
}) => {
  const [uploadedUrl, setUploadedUrl] = useState(value);
  const [loading, setLoading] = useState(false);

  const uploadImage = async (file) => {
    setLoading(true);
    const options = {
      maxSizeMB: 0.01,
    };

    const compressedFile = await imageCompression(file, options);

    await sanityClient.assets
      .upload('image', compressedFile)
      .then((document) => {
        if (document && document.url) {
          setLoading(false);
          setUploadedUrl(document.url);
          swal('Upload success', ' ', 'success');
          if (onSuccess) {
            onSuccess(document.url);
          }
        }
        // console.log('The file was uploaded!', document && document.url);
      })
      .catch((error) => {
        swal('Upload failed', ' ', 'error');
      });
  };

  return (
    <div className={`flex flex-col mt-8 ${loading && 'w-full'}`}>
      {uploadedUrl && !loading && (
        <div>
          <img
            className="object-contain h-56 w-full"
            src={uploadedUrl}
            alt={title}
          />
        </div>
      )}

      <div className="max-w-2xl rounded-lg shadow-x">
        <div className="m-">
          {loading && (
            <div className=" flex justify-center">
              <div
                style={{ 'border-top-color': 'transparent' }}
                className="w-16 h-16 border-4 border-green-600 border-double rounded-full animate-spin"
              />
            </div>
          )}
          {!loading && !uploadedUrl && (
            <label className="inline-block  text-black font-bold mb-2 uppercase">
              {title}
            </label>
          )}

          <div className="flex items-center justify-center w-full">
            {!uploadedUrl && !loading && (
              <label className="flex flex-col w-full h-32 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                    Attach a file
                  </p>
                </div>
                <input
                  type="file"
                  className="opacity-0"
                  onChange={(e) => uploadImage(e.target.files[0])}
                />
              </label>
            )}
          </div>
        </div>
        {hasButton && !loading && (
          <div className="flex items-center justify-center mt-4">
            <label>
              <p className="text-xl text-center w-full bg-blue-200 rounded-xl py-2">
                {uploadedUrl ? 'Change Image ' : buttonTitle}
              </p>
              <input
                type="file"
                className="opacity-0"
                onChange={(e) => uploadImage(e.target.files[0])}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
