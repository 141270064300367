import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { GlobalStyle } from './theme';

import Index from '../pages/index';
import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';

import AddStudent from '../pages/student/add';
import allStudents from '../pages/student/viewAll';
import EditStudent from '../pages/student/edit';
import StudentDashboard from '../pages/student/dashboard';

import RegisterMember from '../pages/RegisterMember';

import Error404 from '../pages/404';

import Login from '../pages/Login';

const PublicRoute = ({ component: Component, access, ...rest }) => {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn.value);
  const user = useStoreState((state) => state.user.data);

  return (
    <Route
      {...rest}
      render={(props) => {
        const redirectTo = '/dashboard';
        if (isLoggedIn && user.type !== 'student') {
          return <Redirect to={redirectTo} />;
        }

        if (isLoggedIn && user.type === 'student') {
          return <Redirect to="/student/dashboard" />;
        }

        if (access !== user.type) {
          return <Redirect to="/not-allowed" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const PrivateRoute = ({ component: Component, access, ...rest }) => {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn.value);
  const user = useStoreState((state) => state.user.data);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return <Redirect to="/login" />;
        }
        if (access && !access.includes(user.type)) {
          return <Redirect to="/not-allowed" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" component={Index} />
          <PublicRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/profile" component={Profile} />

          <PrivateRoute
            access={['admin']}
            exact
            path="/student/add"
            component={AddStudent}
          />
          <PrivateRoute
            access={['admin']}
            exact
            path="/student/all"
            component={allStudents}
          />
          <PrivateRoute
            access={['student']}
            exact
            path="/student/dashboard"
            component={StudentDashboard}
          />
          <PrivateRoute
            access={['admin']}
            exact
            path="/student/:id"
            component={EditStudent}
          />
          <Route exact path="/registerMember" component={RegisterMember} />
          <Route component={Error404} />
        </Switch>
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
};

export default Routes;
