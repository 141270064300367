import React from 'react';
import styled from 'styled-components';

const Input = styled.input``;

const InputGroup = ({
  label,
  errors,
  children,
  groupWidth,
  smallInput,
  value,
  ...otherProps
}) => (
  <>
    {label && (
      <label className="block uppercase tracking-wide  text-gray-700 text-base font-semibold mt-6 mb-4">
        {label}
      </label>
    )}
    {children || (
      <Input
        className={`appearance-none block w-full mt-2  text-gray-700 border  rounded-2xl py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
          errors ? 'border-red-500' : 'border-gray-500'
        }  `}
        value={value}
        {...otherProps}
      />
    )}
    {errors && <p className="text-red-500 text-xs italic">{errors}</p>}
  </>
);

export default InputGroup;
