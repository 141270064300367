import styled from 'styled-components';
import { withFormik } from 'formik';
import * as Yup from 'yup';

const Container = styled.form``;

const SetPasswordForm = ({
  values,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
}) => {
  return (
    <Container onSubmit={handleSubmit} className="pt-5 pb-4">
      <div className="mb-3">
        <input
          type="password"
          className="form - control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-black rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none "
          value={values.password}
          name="password"
          placeholder="New Password"
          onChange={handleChange}
        />
        <p className="mt-4 text-red-500">{errors.password}</p>
      </div>
      <button
        disabled={isSubmitting}
        type="submit"
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500  sm:w-auto sm:text-sm"
      >
        Submit
      </button>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    password: '',
  }),
  validationSchema: Yup.object().shape({
    password: Yup.string().required('Password is required!'),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    props.onSubmit(values).finally(() => {
      setSubmitting(false);
    });
  },
  displayName: 'SetPasswordForm', // helps with React DevTools
})(SetPasswordForm);
