import { FaThList } from 'react-icons/fa';

import StatsCard from './StatsCard';

const StatsContainer = ({ allStats, user }) => {
  const data = [
    {
      id: '2',
      value: allStats.courses || 0,
      title: 'Total Courses',
      color: 'pink',
      iconBackground: 'bg-pink-500',
      background: 'bg-gradient-to-b from-pink-200 to-pink-100 border-pink-500',
      access: 'admin',
    },
    {
      id: '1',
      value: allStats.students || 0,
      title: 'Total Students',
      color: 'blue',
      iconBackground: 'bg-blue-500',
      background: 'bg-gradient-to-b from-blue-200 to-blue-100 border-blue-500',
      access: 'admin',
    },
  ];

  return (
    <div className="flex flex-wrap">
      {data.map((item) => {
        const visible = !!(
          (item.access === 'all' || item.access === user.type) &&
          user.type !== 'student'
        );

        return visible ? (
          <StatsCard
            key={item.id}
            title={item.title}
            value={item.value}
            icon={<FaThList />}
            iconBackground={item.iconBackground}
            background={item.background}
          />
        ) : null;
      })}
    </div>
  );
};

export default StatsContainer;
