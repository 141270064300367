import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { InputGroup, Button, Select } from '../elements';

const Container = styled.form``;

const EditStudentForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  courses,
  setFieldValue,
}) => {
  return (
    <Container onSubmit={handleSubmit}>
      <div className="mb-4">
        <InputGroup
          label="Student Name:"
          name="name"
          type="text"
          placeholder="Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.name && touched.name ? errors.name : undefined}
        />
      </div>
      <div className="mb-4">
        <InputGroup
          label="Student Email"
          name="email"
          type="text"
          placeholder="Enter Center  Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email && touched.email ? errors.email : undefined}
        />
      </div>
      {/* <div className="mb-6">
        <InputGroup
          label="Student Password:"
          name="password"
          type="password"
          placeholder="**************"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.password && touched.password ? errors.password : undefined
          }
        />
      </div> */}
      {/* <div className="mb-6">
        <InputGroup
          label="Country"
          name="country"
          type="text"
          placeholder="Country"
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.country && touched.country ? errors.country : undefined
          }
        />
      </div> */}
      {/* <div className="mb-6">
        <InputGroup
          label="State"
          name="state"
          placeholder="state"
          type="text"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.state && touched.state ? errors.state : undefined}
        />
      </div> */}
      {/* <div className="mb-6">
        <InputGroup
          label="City"
          name="city"
          placeholder="city"
          type="text"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.city && touched.city ? errors.city : undefined}
        />
      </div> */}
      <div className="mb-6">
        {/* <InputGroup
          label="Phone No."
          name="phoneNumber"
          placeholder="Phone No."
          type="text"
          value={values.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.phoneNumber && touched.phoneNumber
              ? errors.phoneNumber
              : undefined
          }
        /> */}
        <div className="mb-4">
          {/* <InputGroup
            label="Address"
            name="address"
            type="text"
            placeholder="Address"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.address && touched.address ? errors.address : undefined
            }
          /> */}
        </div>
        <Select
          label="Select Course"
          data={courses}
          initialValues={values.course}
          onChange={(e) => {
            if (e && e.courseId) {
              setFieldValue('course', {
                name: e.name,
                coursesId: e.courseId,
              });
            }
          }}
        />
        <p className="text-red-500 text-xs italic">{errors.course}</p>
      </div>

      <Button
        type="submit"
        loading={isSubmitting}
        className="mt-4 w-3/12 uppercase justify-center"
      >
        Submit
      </Button>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => {
    return {
      name: initialValues && initialValues.name ? initialValues.name : '',
      country:
        initialValues && initialValues.address && initialValues.address.country
          ? initialValues.address.country
          : '',
      email: initialValues && initialValues.email ? initialValues.email : '',
      address:
        initialValues && initialValues.address && initialValues.address.address
          ? initialValues.address.address
          : '',
      phoneNumber:
        initialValues && initialValues.telephone ? initialValues.telephone : '',
      city:
        initialValues && initialValues.address && initialValues.address.city
          ? initialValues.address.city
          : '',
      state:
        initialValues && initialValues.address && initialValues.address.city
          ? initialValues.address.city
          : '',
      course: initialValues && initialValues.course ? initialValues.course : '',
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required('Center Name is required!'),
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    course: yup.object().required('course is required!'),
  }),

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    setSubmitting(false);
    props.handleSubmit(values);
    resetForm();
  },
  displayName: 'EditStudentForm', // helps with React DevTools
})(EditStudentForm);
