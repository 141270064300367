import { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { isEmpty } from 'lodash';
import swal from 'sweetalert';

import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { StudentTable } from '../../components/tables';
import { EmptyState, Loading } from '../../components/elements';
import ForgotPasswordModal from '../../components/modal/ForgotPasswordModal';

const allStudentsQuery = gql`
  query allStudentsQuery($userType: UserType) {
    allUsers(type: $userType) {
      id
      name
      userId
      email
      status
      course {
        name
        courseId
        status
      }
    }
  }
`;

const editStudentMutation = gql`
  mutation updateUser($id: ID!, $input: UpdateUserInput) {
    updateUser(Id: $id, input: $input) {
      id
    }
  }
`;

const setNewPasswordMutation = gql`
  mutation setNewPassword($id: ID!, $input: SetNewPassword) {
    setNewPassword(Id: $id, input: $input) {
      success
    }
  }
`;

const AllCustomer = () => {
  const [studentId, setStudentId] = useState(null);
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] =
    useState(false);
  const { data, loading, refetch } = useQuery(allStudentsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userType: 'student',
    },
  });

  const [executeUserUpdateMutation, { data: data1, loading: loading1, error }] =
    useMutation(editStudentMutation);

  const [
    executeSetNewPasswordMutation,
    { data: data2, loading: loading2, error: error1 },
  ] = useMutation(setNewPasswordMutation);

  const allStudents = data && data.allUsers ? data.allUsers : [];

  const handleUserUpdate = (val) => {
    swal('Are you sure you want to do disable this user?', {
      buttons: [
        {
          text: 'Yes',
          value: true,
          visible: true,
          closeModal: true,
        },
        {
          text: 'Cancel',
          value: false,
          visible: true,
          closeModal: true,
        },
      ],
    }).then(async (value) => {
      if (value) {
        await executeUserUpdateMutation({
          variables: {
            id: val.id,
            input: { status: 'notActive' },
          },
        });
      }
    });
  };

  useEffect(() => {
    if (error) {
      swal('Oops', 'Student can not be updated', 'error');
    }
    if (data1) {
      refetch();
      swal('Congrats', 'Student updated', 'success');
    }
  }, [data1, error]);

  useEffect(() => {
    if (error1) {
      swal('Oops', 'Something went wrong Please try again', 'error');
    }
    if (data2 && data2.setNewPassword.success) {
      setIsForgotPasswordModalVisible(false);
      setStudentId(null);
      refetch();
      swal('Congrats', 'Password updated', 'success');
    }
  }, [data2, error1]);

  const handleForgotPassword = async (val) => {
    await executeSetNewPasswordMutation({
      variables: {
        id: studentId,
        input: {
          password: val.password,
        },
      },
    });
  };

  return (
    <DashboardLayout>
      <Seo title="All Students" description="Iris Center All Students" />

      <h1 className="text-4xl text-center text-green-600 border-b-2 pb-2	m-5">
        All Students
      </h1>
      {isEmpty(allStudents) && <EmptyState subTitle="Please add a Customer" />}
      {(loading || loading1) && <Loading />}

      {!isEmpty(allStudents) && (
        <div className="w-full pb-4 md:px-0 md-8 mb-16 b text-gray-800 leading-normal">
          <StudentTable
            data={allStudents}
            handleUserStatusChange={handleUserUpdate}
            handleSetNewPasswordModal={() =>
              setIsForgotPasswordModalVisible(true)
            }
            setStudentId={setStudentId}
          />
        </div>
      )}
      {isForgotPasswordModalVisible && (
        <ForgotPasswordModal
          loading={loading2}
          handleSubmit={(val) =>
            handleForgotPassword({
              id: studentId,
              password: val.password,
            })
          }
          handleClose={() => {
            setIsForgotPasswordModalVisible(false);
            setStudentId(null);
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default AllCustomer;
