import { AiFillCloseCircle } from 'react-icons/ai';

const Modal = ({ children, title, handleClose }) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4">
              <div className="mt-3 sm:mt-0 sm:text-left w-full">
                <div className="flex justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 uppercase">
                    {title}
                  </h3>
                  <button
                    type="button"
                    className="text-xl leading-6 font-medium text-red-400 uppercase"
                    onClick={handleClose}
                  >
                    <AiFillCloseCircle />
                  </button>
                </div>
                <div className="mt-2">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
