import Layout from '../components/Layout';

function Index(props) {
  // setTimeout(function () {
  //   window.location = '/login';
  // }, 50);

  return (
    <Layout>
      <div className="flex justify-center items-center h-80">
        <a
          href="/registerMember"
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-6 px-6 rounded-full text-xl"
        >
          Register as new member
        </a>
      </div>
    </Layout>
  );
}

export default Index;
