import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useStoreState } from 'easy-peasy';

import Seo from '../../components/Seo';
import DashboardLayout from '../../components/dashboard/DashboardLayout';
import StudentProfile from '../../components/StudentProfile';
import PdfRender from '../../components/PdfRender';

import PdfLists from '../../components/PdfLists';

import { Loading } from '../../components/elements';

const singleStudentQuery = gql`
  query singleUser($id: ID!) {
    singleUser(id: $id) {
      id
      userId
      name
      email
      telephone
      course {
        name
        courseId
        status
      }
      address {
        city
        state
        country
        address
      }
    }
  }
`;

const courseMaterialQuery = gql`
  query courseMaterial {
    getCourseMaterials {
      fileName
      fileUrl
      createdAt
    }
  }
`;

const StudentDashboard = () => {
  const [url, setUrl] = useState(false);

  const user = useStoreState((state) => state.user.data);

  const { data, loading } = useQuery(singleStudentQuery, {
    variables: {
      id: user.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: data1, loading: loading1 } = useQuery(courseMaterialQuery, {
    variables: {
      id: user.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const student = data && data.singleUser ? data.singleUser : {};

  const allPdf =
    data1 && data1.getCourseMaterials ? data1.getCourseMaterials : [];

  return (
    <DashboardLayout>
      <Seo title="Dashboard" description="Iris Center Dashboard" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16 text-gray-800 leading-normal">
        <div className="px-2">
          {loading && <Loading />}
          <StudentProfile data={student} totalPdf={allPdf.length} />
        </div>
        {loading1 && <Loading />}
        {!url && (
          <div className="px-4 flex items-center justify-center mt-12 flex-col">
            <h1 className="font-medium leading-tight text-4xl mt-0 mb-2 text-green-600 uppercase">
              PDF LIST
            </h1>
            <div className="mt-10 w-full">
              <PdfLists allPdf={allPdf} onClick={(val) => setUrl(val)} />
            </div>
          </div>
        )}

        {url && <PdfRender fileUrl={url} handleClose={() => setUrl(false)} />}
      </div>
    </DashboardLayout>
  );
};

export default StudentDashboard;
