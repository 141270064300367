import { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import swal from 'sweetalert';
import { find } from 'lodash';

import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { EditStudentForm } from '../../components/forms';
import { Loading } from '../../components/elements';

const allCoursesQuery = gql`
  query allCoursesQuery {
    allCourses {
      name
      courseId
      status
    }
  }
`;

const singleStudentQuery = gql`
  query singleUser($id: ID!) {
    singleUser(id: $id) {
      id
      userId
      name
      email
      telephone
      course {
        name
        courseId
        status
      }
      address {
        city
        state
        country
        address
      }
    }
  }
`;

const EditStudentMutation = gql`
  mutation updateUser($id: ID!, $input: UpdateUserInput) {
    updateUser(Id: $id, input: $input) {
      id
    }
  }
`;

const EditStudent = ({ match }) => {
  const { id } = match.params;

  const { data, loading, refetch } = useQuery(singleStudentQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: data2, loading: loading2 } = useQuery(allCoursesQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const [executeMutation, { data: data1, loading: loading1, error }] =
    useMutation(EditStudentMutation);

  const allCourses = data2 && data2.allCourses ? data2.allCourses : [];

  const studentData = data && data.singleUser ? data.singleUser : {};

  const handleSubmit = async (formData) => {
    const course = find(allCourses, (c) => c.name === formData.course.name);

    const obj = {
      name: formData.name,
      telephone: formData.phoneNumber,
      course: {
        name: course.name,
        courseId: course.courseId,
      },
      address: {
        city: formData.city,
        state: formData.state,
        country: formData.country,
        address: formData.address,
      },
    };

    await executeMutation({
      variables: {
        id,
        input: obj,
      },
    });
  };

  useEffect(() => {
    if (error) {
      swal('Oops', 'Student can not be updated', 'error');
    }
    if (data1) {
      refetch();
      swal('Congrats', 'Student updated', 'success');
    }
  }, [data1, error]);

  return (
    <DashboardLayout>
      <Seo title="Add Customer" description="Iris Center Add Customer" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16 text-gray-800 leading-normal">
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-start-2 col-span-4">
            {(loading || loading1 || loading2) && <Loading />}
            {!loading && !loading1 && (
              <EditStudentForm
                enableReinitialize
                initialValues={studentData}
                handleSubmit={handleSubmit}
                courses={allCourses}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EditStudent;
