import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';

// import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div`
  margin-top: 2rem;
  @media print {
    display: none;
  }
`;

// const PdfRender = ({ url }) => (
//   <Container className="flex justify-center mt-32">
//     <Document
//       file="https://cdn.sanity.io/files/yppiadif/production/ac8ca310173e439595d21e5fe29d24254bdfb974.pdf"
//       onContextMenu={(e) => e.preventDefault()}
//       className="pdf-container"
//     >
//       <Page pageNumber={1} />
//     </Document>
//   </Container>
// );

// export default PdfRender;

const PdfRender = ({ fileUrl, handleClose }) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
      <Container>
        <div className="flex justify-end mr-5 mb-5">
          <button
            type="button"
            className="bg-red-400 rounded-full p-2 inline-flex items-center justify-center text-white   focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 "
            onClick={handleClose}
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <Viewer
          fileUrl={fileUrl}
          renderLoader={() => (
            <div style={{ width: '240px' }}>
              <h1>Loading</h1>
            </div>
          )}
        />
      </Container>
    </Worker>
  );
};

export default PdfRender;
