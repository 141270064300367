import React from 'react';
import styled from 'styled-components';
import { ImSpinner6 } from 'react-icons/im';

const ButtonStyle = styled.button``;

const Button = ({ children, loading, className, ...otherProps }) => (
  <ButtonStyle
    type="submit"
    disabled={loading}
    className={`inline-flex text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 rounded text-lg transition duration-500 ease-in-out transform hover:-translate-y-1 ${className}`}
    {...otherProps}
  >
    {loading && (
      <ImSpinner6 className="text-white animate-spin h-10 w-10 mr-3" />
    )}
    {!loading && children}
  </ButtonStyle>
);

export default Button;
