import { Modal, Loading } from '../elements';

import { SetPasswordForm } from '../forms';

const ForgotPasswordModal = ({ handleSubmit, handleClose, loading }) => {
  return (
    <Modal title="Set New Password" handleClose={handleClose}>
      <SetPasswordForm onSubmit={handleSubmit} />
      {loading && <Loading />}
    </Modal>
  );
};

export default ForgotPasswordModal;
