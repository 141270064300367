import React, { useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TableHead } from '../elements';

const tableHeadData = [
  { name: 'S.NO.', width: 'w-1' },
  { name: 'Student ID', width: 'w-1/5' },
  { name: 'Name' },
  { name: 'Email-ID' },
  { name: 'Course', width: 'w-3/5' },
  { name: 'Status', width: 'w-1/12' },
  { name: 'More' },
];

const Tr = styled.tr``;

const StudentTable = ({
  data,
  setStudentId,
  handleUserStatusChange,
  handleSetNewPasswordModal,
}) => {
  const [show, setShow] = useState(null);

  const groupedClass =
    'bg-gray-100 text-gray-900 block px-4 py-2 cursor-pointer text-xs account-link hover:text-white rounded-md m-1 transition duration-500 ease-in-out transform w-full text-left';

  return (
    <div className="shadow-xl	 px-1 md:px-1 pt-4 md:pt-7 pb-5 overflow-y-auto">
      <table className="w-full border-collapse border  ">
        <TableHead data={tableHeadData} />
        <tbody className="w-full">
          {data.map((item, index) => {
            const tagClass =
              item.status === 'active'
                ? ' bg-green-200 text-green-700'
                : ' bg-red-200 text-red-700';

            console.log('user', item.id);

            return (
              <Tr
                className="h-20 text-sm leading-4	 text-gray-800 bg-white hover:bg-gray-100 	divide-x divide-gray-100 border-2"
                key={item.id || Math.random()}
              >
                <td className="p-2">{index + 1}</td>
                <td className="p-2">{item.userId || ''}</td>
                <td className="p-2">{item.name || ''}</td>
                <td className="p-2">{item.email || ''}</td>
                <td className="p-2">
                  {item && item.course && item.course.name
                    ? item.course.name
                    : ''}
                </td>
                <td className="p-2">
                  <div
                    className={`text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 ${tagClass} rounded-full`}
                  >
                    {item.status.toUpperCase() || ''}
                  </div>
                </td>

                <td className="p-2 2xl:px-0">
                  {show === index ? (
                    <button
                      type="button"
                      onClick={() => setShow(null)}
                      className="focus:outline-none pl-1 text-xl"
                    >
                      <BiDotsHorizontalRounded />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setShow(index)}
                      className="focus:outline-none pl-1 text-xl"
                    >
                      <BiDotsHorizontalRounded />
                    </button>
                  )}
                  {show === index && (
                    <div className="dropdown-content bg-white shadow w-36 absolute z-30 right-0 mr-6">
                      <Link to={`/student/${item.id}`} className={groupedClass}>
                        <p>Edit</p>
                      </Link>
                      {item.status === 'active' && (
                        <button
                          type="button"
                          className={groupedClass}
                          onClick={() => {
                            handleUserStatusChange(item);
                            setShow(null);
                          }}
                        >
                          <p>Set Disabled</p>
                        </button>
                      )}
                      {item.status === 'active' && (
                        <button
                          type="button"
                          className={groupedClass}
                          onClick={() => {
                            setShow(null);
                            handleSetNewPasswordModal();
                            setStudentId(item.id);
                          }}
                        >
                          <p>Set New Password</p>
                        </button>
                      )}
                    </div>
                  )}
                </td>
              </Tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default StudentTable;
