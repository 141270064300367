import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';

import { AiOutlineUser } from 'react-icons/ai';

import Sidebar from './Sidebar';
import { Dropdown } from '../elements';

const adminSideBar = [
  {
    id: 1,
    name: 'Manage Students',
    icon: <AiOutlineUser />,
    hasSubMenu: true,
    access: 'admin',
    subMenu: [
      {
        id: 1.1,
        name: 'Add Student',
        href: '/student/add',
      },
      {
        id: 1.2,
        name: 'View All',
        href: '/student/all',
      },
    ],
  },
];

const DashboardLayout = ({ children }) => {
  const [sidebarItems, setSidebarItems] = useState([]);
  const user = useStoreState((state) => state.user.data);

  useEffect(() => {
    setSidebarItems([...adminSideBar]);
  }, []);

  return (
    <div>
      <Sidebar sidebarItems={sidebarItems} user={user} />
      <div className="ml-0 md:ml-56 md:w-auto">
        <header className="w-full items-center bg-white py-2 px-6 hidden sm:flex border-b-4	">
          <div className="w-1/2" />
          <div className="w-1/2 flex justify-end  items-center">
            <p className="text-xl text-green-600	">
              Hi,
              {user.name}
            </p>
            <Dropdown />
          </div>
        </header>
        <div className="pb-10">
          <div className="md:px-1 mx-auto w-full mt-10 ">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
