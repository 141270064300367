import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const navItems = [
  // { name: 'Home', href: '/' },
  // { name: 'About Us', href: '/' },
  // { name: 'About Iridology', href: '/' },
  // { name: 'Contact Us', href: '/' },
];

const Navbar = () => {
  const location = useLocation();
  const { pathname } = location;

  const [visible, setVisible] = useState(false);

  return (
    <header>
      <nav
        className="
          flex flex-wrap
          items-center
          justify-between
          w-full
          py-4
          md:py-4
          px-4
          md:px-16
          text-lg text-gray-700
          bg-white
        "
      >
        <div>
          <a href="/">
            <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-10 h-10 text-white p-2 bg-purple-500 rounded-full"
                viewBox="0 0 24 24"
              >
                <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
              </svg>
              <span className="ml-3 text-xl">ERDO EDUCATION</span>
            </a>
          </a>
        </div>

        <div className=" md:hidden -mt-6" onClick={() => setVisible(!visible)}>
          {visible ? (
            <img
              src="https://img.icons8.com/ios-glyphs/30/000000/cancel.png"
              alt="close"
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="menu-button"
              className="h-6 w-6 cursor-pointer md:hidden block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </div>

        <div
          className={` w-full md:flex md:items-center md:w-auto ${
            !visible ? 'hidden' : ''
          }`}
        >
          <ul
            className="
              pt-4
              text-base text-gray-700
              md:flex
              md:justify-between
              md:pt-0"
          >
            {/* {navItems.map((item) => (
              <li key={item.name}>
                <a className="md:p-4 py-2 block" href="/">
                  {item.name}
                </a>
              </li>
            ))} */}
            <li>
              {pathname !== '/login' && (
                <a
                  className="px-2 md:px-4 mt-2 py-2 block bg-purple-500 hover:bg-purple-700 text-white  rounded"
                  href="/login"
                >
                  Log In
                </a>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
