import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { InputGroup, Button } from '../elements';

const Container = styled.form``;

const LoginForm = ({
  values,
  touched,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
}) => {
  return (
    <Container className="w-full max-w-lg" onSubmit={handleSubmit}>
      <div className="mb-4">
        <InputGroup
          label="Email"
          name="email"
          type="text"
          placeholder="Enter Your Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email && touched.email ? errors.email : undefined}
        />
      </div>
      <div className="mb-6">
        <InputGroup
          label="Password"
          name="password"
          type="password"
          placeholder="**************"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.password && touched.password ? errors.password : undefined
          }
        />
      </div>

      <Button type="submit">Sign In</Button>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    password: yup.string().required('Password is required!'),
  }),

  handleSubmit: (values, { setSubmitting, props }) => {
    props.handleSubmit(values).then(() => {
      setSubmitting(false);
    });
  },
  displayName: 'LoginForm', // helps with React DevTools
})(LoginForm);
