// import Datetime from 'react-datetime';
import styled from 'styled-components';
import moment from 'moment';

const Container = styled.div`
  input {
    border-radius: 20px;
    padding: 1.25rem 2rem;
    width: 100%;
    :focus,
    :focus-visible,
    :active {
      outline: none;
      border: none;
    }
  }
`;
const DatePickerInput = ({ label, className, value, onChange, errors }) => {
  return (
    <Container className="mb-4">
      <label className="block uppercase tracking-wide  text-gray-700 text-base font-semibold mt-6 mb-4">
        {label}
      </label>
      <input
        type="date"
        name="dayTime"
        value={moment(value).format('YYYY-MM-DD')}
        onChange={(e) => onChange(moment(e.currentTarget.value).toISOString())}
        className={`h-auto bg-white rounded-2xl border border-gray-500 focus:border-primary focus:bg-purple-200 focus:border-primary text-base outline-none text-gray  resize-none leading-6 transition-colors duration-200 ease-in-out ${className}`}
      />
      {errors && <p className="text-red-500 text-xs italic">{errors}</p>}
    </Container>
  );
};

export default DatePickerInput;
