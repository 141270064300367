export default {
  debug: process.env.NODE_ENV === 'development',
  siteName: 'IRIS Center',
  projectKey: 'IRIS-Center',
  graphQlUri:
    process.env.REACT_APP_WEB_URL ||
    'https://erdo-education-api.vercel.app/api/graphql',
  // graphQlUriDev: 'https://erdo-education-api.vercel.app/api/graphql',
  graphQlUriDev: 'https://erdo-education-api.vercel.app/api/graphql',
  sanityId: 'korwtyz9',
  sanityToken:
    'skK0NsFVuzethDfE1S9Fmr3Mi3YWyrj0TqOrlKZuoSDF5k1jMA35AaywdvbSM3c7SUhyUOC0oMnYG00fQkOCl3f6l3cLlGjHH17EnpFtANdUxR2rqvR0LVhLcbEPlmnbEyibIehqwgCHk0MdQMHL0QWjR9WGfMyA7ZosEugKl2KqmKEZX1kq',
  email: 'no-reply@site.com',
  telephone: '+1123123123',
  address: 'London, UK',
};
