import { useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import swal from 'sweetalert';
import { find } from 'lodash';

import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { StudentForm } from '../../components/forms';
import { Loading } from '../../components/elements';

const allCoursesQuery = gql`
  query allCoursesQuery {
    allCourses {
      name
      courseId
      status
    }
  }
`;

const addStudentMutation = gql`
  mutation registerStudent($input: RegisterInput) {
    register(input: $input) {
      user {
        id
      }
    }
  }
`;

const AddCustomer = ({ history }) => {
  const { data, loading } = useQuery(allCoursesQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const [executeMutation, { data: data1, loading: loading1, error }] =
    useMutation(addStudentMutation);

  const handleSubmit = (formData) => {
    const course = find(data.allCourses, (c) => c.courseId === formData.course);

    const obj = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      telephone: formData.phoneNumber,
      course: {
        name: course.name,
        courseId: course.courseId,
      },
      address: {
        city: formData.city,
        state: formData.state,
        country: formData.country,
        address: formData.address,
      },
      type: 'student',
    };

    executeMutation({
      variables: {
        input: obj,
      },
    });
  };

  const allCourses = data && data.allCourses ? data.allCourses : [];

  useEffect(() => {
    if (error) {
      swal('Oops', 'Student can not be created', 'error');
    }
    if (data1) {
      swal('Congrats', 'student Created', 'success');
      history.push(`/student/all`);
    }
  }, [data1, error]);

  return (
    <DashboardLayout>
      <Seo title="Add Agency" description="Iris Center Add Agency" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16 text-gray-800 leading-normal">
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-start-2 col-span-4">
            {(loading1 || loading) && <Loading />}
            <StudentForm handleSubmit={handleSubmit} courses={allCourses} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddCustomer;
