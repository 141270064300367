import { createGlobalStyle } from 'styled-components';

const mainBrandColor = '#8B5CF6';
const darkShades = '#8B5CF6';
const lightAccent = '#c4adfa';

const theme = {
  mainBrandColor,
  darkShades,
  lightAccent,
  primaryColor: '#8B5CF6',
  secondaryColor: '#28c08e',
};

export default theme;

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    font-family: poppins , sans-serif;
  }
  .bg-sidebar { background: ${theme.mainBrandColor}; }
  .cta-btn { color: ${theme.mainBrandColor}; }
  .active-nav-link { background: ${theme.darkShades}; }
  .nav-item:hover { background: ${theme.darkShades}; }
  .account-link:hover { background: ${theme.mainBrandColor}; }
`;

export { GlobalStyle };
